import React, { Fragment } from 'react'
import './footer.styles.scss'
//import { Link } from "gatsby"
import Link from '../Link'
// import whatsAppImage from '../../images/Path 257.svg'
// import instagramImage from '../../images/Path 254.svg'
// import twitterImage from '../../images/twitter.svg'
import logo from '../../images/nav-logo.svg'

import linkedIn from '../../images/linked-in.png'
import facebook from '../../images/facebook.png'

const Footer = ({ imageUrl, title, linkTo, linkStyle, linkText, rights, bookingBtn }) => (
	<div className="footer-container">
		<div className="footer-parent">
			<div className="image-parent">
				<img src={logo} className="crad-image" alt="" />
			</div>
			<div className="content-parent">
				<h3 className="title">{title}</h3>
				<div className="imgContainer">
					{!bookingBtn ? (
						<Fragment>
							<a
								href="https://www.linkedin.com/company/icloud-ready/"
								target="_blank"
								rel="noreferrer noopener"
								aria-label="linked In link"
							>
								<img
									src={linkedIn}
									width="30px"
									height="30px"
									alt="Company LinkedIn page - logo link"
								/>
							</a>

							<a
								href="https://www.facebook.com/icloudready?mibextid=ZbWKwL"
								target="_blank"
								rel="noreferrer noopener"
								aria-label="Company Facebook page - logo link"
							>
								<img
									src={facebook}
									width="30px"
									height="30px"
									alt="facebook logo link"
								/>
							</a>
						</Fragment>
					) : (
						bookingBtn
					)}

					{/* <img src={whatsAppImage} alt="WhatsApp icon" />
					<img src={instagramImage} alt="Instagram icon" />
					<img src={twitterImage} alt="Twitter icon" /> */}
				</div>
			</div>
		</div>
		<div className="copyRights">
			<h5>{rights}</h5>
			<a href="mailto:help@icloud-ready.com?subject=''&body=''" className="link-btn" style={linkStyle}>
				{linkText}
			</a>
		</div>
	</div>
)

export default Footer
