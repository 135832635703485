import React, { useState } from 'react'
import './plans-parent.styles.scss'

import { Steps, Collapse, Button } from 'antd'
import { CheckCircleFilled, CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import usePlans from '../../hooks/stripe/usePlans'
import basicPlanImg from '../../images/basicPlan.svg'
import standardPlanImg from '../../images/standardPlan.svg'
import enterPriseImg from '../../images/epPlan.svg'
import contactImg from '../../images/contact-us.svg'
import { Link } from 'gatsby'

//console.log('usePlans' + JSON.stringify({ usePlans }))
//
const PlansParent = ({ plansContent, products, questions }) => {
	const { Step } = Steps
	const { Panel } = Collapse

	const [navClick, setNavClick] = useState()
	const triggerClick = () => navClick.click()

	// prefetch plans and store it in cache
	usePlans()

	//
	const [current, setCurrent] = useState(0)

	const state = { planName: plansContent.products[current].productName }

	const onChange = value => {
		setCurrent(value)
	}

	return (
		<div className="step-content pricing-step" id="step3">
			<div className="pricePlans">
				{/* <div className="priceSelection">
					<Steps
						type="navigation"
						size="small"
						current={current}
						onChange={onChange}
						className="site-navigation-steps"
					>
						{products.map(product => {
							return <Step key={product.id} status="process" title={product.name} />
						})}
					</Steps>
				</div> */}
				<div className="priceCards">
					{plansContent.products[current].data.map(type => {
						return (
							<div
								style={{
									border:
										type.planTitle == 'Standard' &&
										'1px solid #0097C2',
									height: type.planTitle == 'Standard' && '950px',
								}}
								key={type.planTitle}
								className="priceCard"
							>
								{type.planTitle == 'Standard' && (
									<span className="recommended">Recommended</span>
								)}
								<div className="priceHeader">
									{/* img */}
									{type.planTitle === 'Basic' ? (
										<img src={basicPlanImg} alt="Basic plan icon" />
									) : type.planTitle === 'Standard' ? (
										<img
											src={standardPlanImg}
											alt="Standard plan icon"
										/>
									) : type.planTitle == 'Enterprise' ? (
										<img
											src={enterPriseImg}
											alt="Enterprise plan icon"
										/>
									) : null}
									<h3>{type.planTitle}</h3>
									<h4>
										$1
										<span>/ DTSU * Working Hour</span>
									</h4>
								</div>
								<div className="priceFeature">
									<h4>
										Includes{' '}
										{/* {type.planTitle !== 'Basic' && (
											<ExclamationCircleOutlined />
										)} */}
									</h4>
									<ul>
										{plansContent.products[current].features[
											current
										].items
											.slice(0, 5)
											.map(item => {
												return (
													<li>
														<CheckCircleFilled />{' '}
														{item}
													</li>
												)
											})}
									</ul>
									<Button
										style={{
											backgroundColor:
												type.planTitle == 'Standard' &&
												'#0097c2',
											color:
												type.planTitle == 'Standard' &&
												'#ffff',
										}}
										type="default"
										shape="round"
										onClick={() => {
											localStorage.setItem(
												'state',
												JSON.stringify(state)
											)
										}}
									>
										<Link
											to={
												type.planTitle === 'Basic'
													? '/trial'
													: '/contactus'
											}
											state={state}
										>
											{type.planTitle == 'Basic'
												? 'Choose Plan'
												: 'Contact Us'}
										</Link>
									</Button>
								</div>
							</div>
						)
					})}
				</div>
				<div className="questionSection">
					<h3>Know More</h3>
					<h2>Frequently Asked Questions</h2>
					<Collapse
						expandIcon={({ isActive }) =>
							isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
						}
						expandIconPosition="start"
						defaultActiveKey={['1']}
					>
						{questions.map(question => {
							return (
								<Panel header={question.label} key={question.id}>
									<p>{question.content}</p>
								</Panel>
							)
						})}
					</Collapse>
				</div>
			</div>
			<div className="contactUs">
				<div>
					<h2>Still have unanswered questions?</h2>
					<Link to="/contactus"> Get In Touch </Link>
					<span></span>
				</div>
				<div>
					<img src={contactImg} alt="Contact Us" />
					<span></span>
				</div>
			</div>
			{/* <h2>Still have unanswered questions?</h2>
			<Button type="default" shape="round">
				Get in touch
			</Button> */}
		</div>
	)
}

export default PlansParent
