import React, { useState } from 'react'
import './new-plans-parent.styles.scss'

import { Button } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import usePlans from '../../hooks/stripe/usePlans'
import basicPlanImg from '../../images/basicPlan.svg'
import standardPlanImg from '../../images/standardPlan.svg'
import enterPriseImg from '../../images/epPlan.svg'
import { Link } from 'gatsby'

const tabs = [{ title: 'Property Management' }, { title: 'Customer Engagement' }]

const PlansParent = ({ plansContent }) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0)

	const state = { planName: plansContent.products[0].productName }

	// prefetch plans and store it in cache
	usePlans()

	return (
		<div className="pricePlans">
			<div className="pricing-tabs">
				{tabs.map((tab, index) => {
					const isActive = index === activeTabIndex
					return (
						<div
							className={isActive ? 'pricing-tab active' : 'pricing-tab'}
							onClick={() => setActiveTabIndex(index)}
						>
							{tab.title}
						</div>
					)
				})}
			</div>

			<div className="priceCards">
				{plansContent.products[activeTabIndex].data.map((type, index) => {
					return (
						<div key={type.planTitle} className="priceCard">
							{index === 1 && <span className="recommended">Recommended</span>}
							<div className="priceHeader">
								{index === 0 ? (
									<img src={basicPlanImg} alt="Basic plan icon" />
								) : index === 1 ? (
									<img src={standardPlanImg} alt="Standard plan icon" />
								) : index === 2 ? (
									<img src={enterPriseImg} alt="Enterprise plan icon" />
								) : null}
								<h3>{type.planTitle}</h3>
								<p>{type.planDesc}</p>
								{type.price && (
									<h2>
										<span>${type.price}</span> / Active user / month
									</h2>
								)}
								{[0, 1].includes(index) && activeTabIndex === 1 ? (
									<p>Start from 100 active users</p>
								) : null}
							</div>
							<div className="priceFeature">
								<h4>
									{type.planTitle === 'Basic'
										? 'Includes'
										: type.planTitle === 'Growth'
										? 'Everything in Basic, plus'
										: type.planTitle == 'Enterprise'
										? 'Everything in Growth, plus'
										: null}
								</h4>
								<ul>
									{type.featuresValues.map(feature => {
										return (
											<li>
												<CheckCircleFilled />{' '}
												<span>{feature}</span>
											</li>
										)
									})}
								</ul>
							</div>
							<Button
								style={{
									backgroundColor: type.planTitle == 'Growth' && '#0097c2',
									color: type.planTitle == 'Growth' && '#ffff',
									height: '60px',
									fontWeight: 400,
								}}
								type="default"
								shape="round"
								onClick={() => {
									localStorage.setItem('state', JSON.stringify(state))
								}}
							>
								<Link to={type.linkTo} state={state}>
									{index === 0 ? 'Free Trial' : 'Contact Us'}
								</Link>
							</Button>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default PlansParent
