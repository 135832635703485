import Main from "../../../../src/components/main/main";
import automotiveImg from "../../../../src/images/gif-automotive.gif";
import realstateImg from "../../../../src/images/gif-real.gif";
import serviceImge from "../../../../src/images/gif-services.gif";
import illustration from "../../../../src/images/cover-anim.gif";
import * as React from 'react';
export default {
  Main,
  automotiveImg,
  realstateImg,
  serviceImge,
  illustration,
  React
};