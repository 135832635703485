import React from "react";
import { useSWRConfig } from "swr";
import useAsync from "../useAsync";
import handleFetchResponse from "../../components/util/handleFetchResponse";
import removeStripeCard from "../../components/util/removeStripeCard";
import { message } from "antd";

function useRemoveCard(){
    const { mutate: swrMutate } = useSWRConfig();
    const { run, ...state } = useAsync();

    React.useEffect(
        () => {
            if(state.isSuccess){
                message.success("Card has been successfully removed.");
            }

            if(state.isError){
                message.error("Failed to remove card.")
            }
        },
        [state.isSuccess, state.isError]
    )

    const updatePaymentMethods = ({ customerId, cardId }) => async paymendMethods => {
        const removedPaymentMethod = await removeStripeCard({ customerId, cardId })
                                            .then(handleFetchResponse).then(data => data.removedPaymentMethod);
        return paymendMethods.filter(method => method.id !== removedPaymentMethod.id);
    }

    const remove = ({ customerId, cardId }) => {
        const promise = swrMutate("paymentMethods", updatePaymentMethods({ customerId, cardId }), false);
        run(promise);
    }

    return { mutate: remove, ...state };
}

export default useRemoveCard;