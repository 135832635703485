module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.icloud-ready.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4bdd476c4d244bd0ab99fef65d52bfba@o507169.ingest.sentry.io/5685177","sampleRate":0.7,"environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T6NJL9N","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-166099724-1","AW-10902950737"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#333","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ar"],"defaultLanguage":"en","langKeyForNull":"any","langKeyDefault":"en","fallbackLng":{"ar":["en"]},"useLangKeyLayout":false,"redirect":false,"nsSeparator":".","siteUrl":"https://www.icloud-ready.com","strictMode":true,"pages":[{"matchPath":"/","languages":["en","ar"]},{"matchPath":"/features","languages":["en"]},{"matchPath":"/faqs","languages":["en"]},{"matchPath":"/pricing","languages":["en"]},{"matchPath":"/contactus","languages":["en","ar"]},{"matchPath":"/terms","languages":["en"]},{"matchPath":"/404","languages":["en"]},{"matchPath":"/blogs","languages":["en","ar"]},{"matchPath":"/blog","languages":["en"]},{"matchPath":"/404","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"icloud-ready application","short_name":"icloud-ready","description":"The application is your gate to subscribe to your industry technology platform.","start_url":"/","background_color":"#f7f0eb","theme_color":"#0097c2","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/@hutsoninc/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1252735198684117"},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_3t2hyjc1na4OGzQCKPk7bwTkhRZWPvpA4ln7mms4jqr","apiHost":"https://app.posthog.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
