import React from "react";
import { useSWRConfig } from "swr";
import useAsync from "../useAsync";
import editSubscription from "../../components/util/editStripeSubscription";
import handleFetchResponse from "../../components/util/handleFetchResponse";
import { message } from "antd";

function useEditSubscription(){
    const { run, ...state } = useAsync();
    const { mutate } = useSWRConfig();

    React.useEffect(
        () => {
            if(state.isSuccess){
                message.success("Subscription has been successfully editted.")
            }

            if(state.isError){
                message.error("Failed to edit subscription.")
            }
        },
        [state.isSuccess, state.isError]
    )

    const edit = data => {
        run(
            editSubscription(data)
            .then(handleFetchResponse)
            .then(data => {
                mutate("plans");
                return data;
            })
        );
    }

    return { edit, ...state };
}

export default useEditSubscription;