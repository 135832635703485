import React from 'react'
import './inroduction.styles.scss'
import { Button } from 'antd'
import { RightCircleOutlined } from '@ant-design/icons'
import Image from '../../images/page-clip.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
const Inroduction = ({ products }) => {
	const { t, i18n } = useTranslation()

	return (
		<section className="pillarsSection" style={i18n.language === 'ar' ? { minHeight: 'unset' } : null}>
			<img src={Image} alt="" style={i18n.language === 'ar' ? { top: '-88%' } : null} />
			<div className="introduction">
				<h3 dir={i18n.language === 'ar' ? 'rtl' : 'ltf'}>
					<Trans>Introducing iCloud-Ready Platform</Trans>
				</h3>
				<h2 dir={i18n.language === 'ar' ? 'rtl' : 'ltf'}>
					<Trans>The Next Generation in software</Trans>
				</h2>
				{i18n.language === 'ar' ? (
					<p dir="rtl">
						البرنامج البديل للنظام البشرى , حيث يمكنه الحلول بديلا عن أكثر من 5 أقسام
						ووظائف , نقدم حل متكامل لادارة العقارات من بيع و ايجار و إصدار العقود و ادارة
						.المدفوعات بمنتهى الدقة
					</p>
				) : (
					<p>
						Our cloud-based platform offers an innovative set of tools that can help you
						manage
						<br />
						every aspect of your organization, from accounting and inventory control to
						tenant
						<br />
						relations and contract management
					</p>
				)}
			</div>
		</section>
	)
}

export default Inroduction
