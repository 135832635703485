import React, { useState, useEffect } from 'react'
//import { Link } from "gatsby"
import Link from '../Link'
import './signup-plan.styles.scss'
import CustomButton from '../custom-button/custom-button.component'
import increment from '../../images/increment.svg'

const SignupPlan = ({ productName, planTitle, planPrice, LinkTo, buttonStyle, buttonText }) => {
	const [defaultMin, setDefaultMin] = useState(
		productName === 'Customer Engagement'
			? 100
			: productName === 'Operations Excellence'
			? 1
			: productName === 'Employees Productivity'
			? 100
			: productName === 'Actionable Insights'
			? 1
			: productName === 'Services'
			? 1
			: productName === 'Market Place'
			? 1
			: 0
	)
	const [userNo, setUserNo] = useState(defaultMin)
	const [totPrice, setTotPrice] = useState(planPrice.replace('$', ''))
	const validateNumber = e => {
		if (
			typeof parseInt(e.target.value) !== 'number' ||
			!parseInt(e.target.value) ||
			parseInt(e.target.value) < defaultMin
		) {
			setUserNo(defaultMin)
			return
		}
		if (e.nativeEvent.data && String(e.nativeEvent.data).match(/^[0-9]+$/))
			setUserNo(parseInt(e.target.value))
		else setUserNo(parseInt(e.target.value))
	}
	const validateIncrement = value => {
		if (parseInt(value) < defaultMin) {
			setUserNo(defaultMin)
			return
		}
		setUserNo(value)
	}
	useEffect(() => {
		switch (productName) {
			case 'Customer Engagement':
				setDefaultMin(100)
				break
			case 'Operations Excellence':
				setDefaultMin(1)
				break
			case 'Employees Productivity':
				setDefaultMin(100)
				break
			case 'Actionable Insights':
				setDefaultMin(1)
				break
			case 'Services':
				setDefaultMin(1)
				break
			case 'Market Place':
				setDefaultMin(1)
				break
			default:
				return
		}
		setTotPrice(planPrice.replace('$', '') * userNo)
		localStorage.setItem('qty', userNo)
	}, [productName, defaultMin, userNo])
	return (
		<div className="full-info">
			<div className="main-info">
				<h3 className="info-title">{productName}</h3>
				<h3 className="info-title">{planTitle}</h3>
				<label className="user-label">
					Users
					<input type="text" className="user-no" value={userNo} onChange={validateNumber} />
					<div className="increment-parent">
						<img src={increment} alt="increment" />
						<div className="incrementer">
							<div
								className="up"
								onClick={() => validateIncrement(userNo + 1)}
								aria-hidden="true"
							></div>
							<div
								className="down"
								onClick={() => validateIncrement(userNo - 1)}
								aria-hidden="true"
							></div>
						</div>
					</div>
				</label>
				<div className="info-price">
					<span className="value">$ {totPrice}</span>
				</div>
				<CustomButton style={buttonStyle} className="btn btn-link info-btn">
					<Link to={LinkTo} className="link-item">
						{buttonText}
					</Link>
				</CustomButton>
			</div>
		</div>
	)
}

export default SignupPlan
