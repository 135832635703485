import React from "react";
import { useSWRConfig } from "swr";
import useAsync from "../useAsync";
import cancelStripeSubscription from "../../components/util/cancelStripeSubscription";
import handleFetchResponse from "../../components/util/handleFetchResponse";
import { message } from "antd";

function useCancelSubscription(){
    const { mutate } = useSWRConfig();
    const { run, ...state } = useAsync();

    React.useEffect(
        () => {
            if(state.isSuccess){
                message.success("Subscription has been successfully canceled.")
            }

            if(state.isError){
                message.error("Failed to cancel subscription")
            }
        },
        [state.isSuccess, state.isError]
    )

    const cancel = ({ stripeId, subscriptionId }) => run(
        cancelStripeSubscription({ stripeId, subscriptionId })
        .then(handleFetchResponse)
        .then(data => {
            mutate(
                "plans",
                async (plans) => (
                    plans.map(plan => {
                        return plan.subscriptionData?.id === subscriptionId ? 
                            {...plan, isSubscribed: false, subscriptionData: null} :
                             plan;
                    })
                ),
            );

            return data;
        })
    );

    return { cancel, ...state }
}

export default useCancelSubscription;