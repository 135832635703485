import Porducts from "../../../../src/components/products/products";
import Img1 from "../../../../src/images/product1.jpg";
import Img2 from "../../../../src/images/product2.jpg";
import Img3 from "../../../../src/images/product3.jpg";
import Img4 from "../../../../src/images/product4.jpg";
import Img5 from "../../../../src/images/product5.jpg";
import Img6 from "../../../../src/images/product6.jpg";
import * as React from 'react';
export default {
  Porducts,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  React
};