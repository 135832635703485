import auth0js from 'auth0-js'
import { navigate } from 'gatsby'
import tracking from '../../utils/tracking'

export const isBrowser = typeof window !== 'undefined'

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let profile = false

const tokens = {
	accessToken: false,
	idToken: false,
	expiresAt: false,
}

// Only instantiate Auth0 if we’re in the browser.
const auth0 = isBrowser
	? new auth0js.WebAuth({
			domain: process.env.AUTH0_DOMAIN,
			clientID: process.env.AUTH0_CLIENTID,
			redirectUri: process.env.AUTH0_CALLBACK,
			audience: process.env.AUTH0_AUDIENCE,
			responseType: 'token id_token',
			scope: 'openid profile email',
	  })
	: {}

export const login = (screenHint = 'singup') => {
	if (!isBrowser) {
		return
	}
	tracking.eventTrack('login', 'click', 'login')
	auth0.authorize({ state: window.location.pathname, screen_hint: screenHint })
}

export const logout = () => {
	localStorage.setItem('isLoggedIn', false)
	profile = false
	tokens.accessToken = false
	tokens.idToken = false
	tokens.expiresAt = false

	const { protocol, host } = window.location
	const returnTo = `${protocol}//${host}`
	tracking.eventTrack('logout', 'click', 'logout')
	auth0.logout({ returnTo })
}

const setSession = callback => (err, authResult) => {
	if (!isBrowser) {
		return
	}

	if (err) {
		console.error(err)
		callback()
		return
	}
	// console.log(authResult)
	if (authResult && authResult.accessToken && authResult.idToken) {
		let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
		tokens.accessToken = authResult.accessToken
		tokens.idToken = authResult.idToken
		tokens.expiresAt = expiresAt
		profile = authResult.idTokenPayload
		localStorage.setItem('isLoggedIn', true)
		// navigate("/account")
		callback(authResult)
	}
}

export const silentAuth = callback => {
	if (!isBrowser) {
		return
	}

	if (!isAuthenticated()) return callback()
	auth0.checkSession({}, setSession(callback))
}

export const handleAuthentication = (callback = () => {}) => {
	if (!isBrowser) {
		return
	}

	auth0.parseHash(setSession(callback))
}

export const getUserInfo = () => {
	if (profile) {
		return profile
	}
}

export const isAuthenticated = () => {
	if (!isBrowser) {
		return
	}

	return localStorage.getItem('isLoggedIn') === 'true' && getUserInfo()
}

export const getAccessToken = () => {
	if (!isBrowser) {
		return ''
	}

	return tokens.accessToken
}

export const getIdToken = () => {
	if (!isBrowser) return ''
	return tokens.idToken
}
