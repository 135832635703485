import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./bootstap-accordion.styles.scss"

const BootstapAccordion = ({accordionList, opened}) => {
  const [activeId, setActiveId] = useState(opened);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  useEffect(() => {
    const toggleActive =(id) => {
      if(activeId===id){
        setActiveId(id)
      } else {
        return;
      }
    }
    toggleActive(opened);
  });
  return (

  <div className="accordion-parent">
    <Accordion defaultActiveKey={activeId}>
    {
      accordionList.map((accordion, index)=> (
        <div key={index} className={activeId === accordion.id ? 'panel-wrap active-panel' : 'panel-wrap'}>
        <Card>
          <Accordion.Toggle as={Card.Header} onClick={() => toggleActive(accordion.id)} className="panel-toggle" eventKey={accordion.id}>
            {accordion.label}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={accordion.id}>
            <Card.Body>{accordion.content}</Card.Body>
          </Accordion.Collapse>
      </Card>
        </div>
      )) 
    }
  </Accordion>
  </div>
)}

export default BootstapAccordion;
