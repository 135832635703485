import React from "react";
import { mutate, useSWRConfig } from "swr";
import { message } from "antd";
import updateStripeCustomer from "../../components/util/updateStripeCustomer";
import handleFetchResponse from "../../components/util/handleFetchResponse";
import useAsync from "../useAsync";

function useEditCustomer(){
    const { mutate: swrMutate } = useSWRConfig();
    const { run, ...state } = useAsync();

    React.useEffect(
        () => {
            if(state.isSuccess){
                message.success("You info has been updated successfully");
            }

            if(state.isError){
                message.error("Failed to update info.");
            }
        },
        [state.isSuccess, state.isError]
    )

    const edit = data => {
        run(
            updateStripeCustomer(data)
            .then(handleFetchResponse)
            .then(({ customer }) => {
                swrMutate("customer", async currentCustomer => ({...currentCustomer, ...customer}), false);
                return customer;
            })
        )
    }

    return { mutate: edit, ...state};
}

export default useEditCustomer;