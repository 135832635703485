import Market from "../../../../src/components/market/market";
import residential from "../../../../src/images/residential.png";
import commercial from "../../../../src/images/commercial.png";
import community from "../../../../src/images/community.png";
import investment from "../../../../src/images/investment.png";
import * as React from 'react';
export default {
  Market,
  residential,
  commercial,
  community,
  investment,
  React
};