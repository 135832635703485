import React, { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { Button, Select } from 'antd'
import { RightCircleOutlined } from '@ant-design/icons'

import Image1 from '../../images/image 13.png'
import Image2 from '../../images/image 20.png'

import './market.styles.scss'
import '../provenApproach/provenApproach.styles.scss'

const { Option } = Select

const Market = ({ marketData }) => {
	const [current, setCurrent] = useState(marketData[0])

	const { i18n } = useTranslation()

	const handleChange = id => {
		setCurrent(marketData.find(item => item.id == id))
	}

	return (
		<Fragment>
			{i18n.language === 'ar' ? (
				<div className="provenApproach" style={{ padding: '10px 0', minHeight: 'unset' }}>
					<div className="imgContainer">
						<img
							src={Image1}
							alt="IMG"
							className="img1"
							style={{ width: '280px', height: '280px' }}
						/>
						<img src={Image2} alt="IMG" className="img2" style={{ width: '60%' }} />
					</div>
					<div className="infoSection">
						<h2 dir="rtl">نظام إدارة الأملاك</h2>
						<ul dir="rtl">
							<li>تسجيل بيانات المستأجرين بدقة و تفصيل سواء كانو افراد او شركات </li>
							<li>
								الربط الكامل مع نظام القيمة المضافة و احتسابها وفقا للنشاط سواء
								تجارى او سكنى
							</li>
							<li>النظام يتيح طباعه عقود الايجار الخاصة بالمؤسسة </li>
							<li>يمكنك التحصيل من من العملاء وفقا لنوع التحصيل</li>
							<li>ضم و فك الوحدات بسهولة </li>
							<li>الربط الكامل مع الحسابات العامة</li>
							<li>يمكن التعديل على العقود بسهولة </li>
							<li>يمكنك الاطلاع على التقارير التحليلية بالتفاصيل</li>
							<li>
								يدعم النظام اصدار العقود وتحصيل الدفعات بتاريخ ميلادي او هجري
								(ام القرى) .
							</li>
						</ul>
					</div>
				</div>
			) : (
				<div className="market">
					<div className="marketImges">
						<img src={current.img[0]} alt={current.title} />
					</div>
					<div className="marketInfo">
						<h2>
							Keep your portfolio <br /> of homes and apartments <br /> in one single
							database
						</h2>
						<Select
							onChange={e => handleChange(e)}
							defaultValue="residential"
							bordered={false}
						>
							{marketData.map(item => {
								return (
									<Option key={item.id} value={item.id}>
										{item.title}
									</Option>
								)
							})}
						</Select>
						<p>{current.subtitle}</p>
						{/* <div className="book">
					<div>
						<h4>Area</h4>
						<h5>250 M2</h5>
					</div>
					<div>
						<h4>Rent</h4>
						<h5>50.000$/Y</h5>
					</div>
					<button>Book Now</button>
				</div> */}
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default Market
