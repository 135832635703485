import React from 'react'
import './custom-card.styles.scss'
import CustomButton from '../custom-button/custom-button.component'
//import { Link } from "gatsby"
import Link from '../Link'

const CustomCard = ({
	onBtnClick,
	state,
	imageUrl,
	title,
	priceText,
	subtitle,
	linkTo,
	linkStyle,
	linkText,
	buttonStyle,
	buttonText,
	linkLearnTo,
	linkLearnStyle,
	buttonId
}) => {
	return (
		<div className="card-container">
			<div className="image-parent">
				<img src={imageUrl} className="crad-image" alt="card img" />
			</div>
			<div className="content-parent">
				<h3 className="title">{title}</h3>
				{priceText ? <h4 className="price-text">{priceText}</h4> : null}
				<h5 className="subtitle">{subtitle}</h5>
				<div className="actions-parent">
					<Link to={linkLearnTo} className="link-btn" style={linkLearnStyle}>
						{linkText}
					</Link>
					<CustomButton
						style={buttonStyle}
						onClick={onBtnClick ? onBtnClick : () => {}}
						state={state ? state : ''}
						id="custom-btn"
						className="link-btn"
					>
						<Link to={linkTo} id={buttonId} className="link-button" style={linkStyle}>
							{buttonText}
						</Link>
					</CustomButton>
				</div>
			</div>
		</div>
	)
}

export default CustomCard
