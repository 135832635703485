import { getUserInfo } from '../components/util/auth'
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel'

// Tracking Helpers

// if(typeof window !== `undefined`) {
// console.log("Reading GTAG", window.gtag);
// }

const sendConversionToSlack = (conversionType, metadata) => {
	const user = getUserInfo()

	let text

	switch (conversionType) {
		case 'free_trial':
			text = `*Free Trial Conversion* \n\n ${
				user ? user.email : 'anonymous user'
			} has started a free trial`
			break
		case 'contact_us':
			text = `
				*Contact Us Conversion* \n\n ${user ? user.email : metadata.email} has submitted the contact us form
				\n\n *Metadata* \n \`\`\`${JSON.stringify(metadata)}\`\`\`
			`
			break
		case 'request_meeting':
			text = `*Request Meeting Conversion* \n\n ${
				user ? user.email : 'anonymous user'
			} has booked a free demo`
			break
	}

	fetch('/.netlify/functions/slackConversions', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ text }),
	}).catch(err => {
		console.log('Error sending conversion to slack', err)
	})
}

const tracking = {
	eventTrack: function (eventCategory, eventAction, eventLabel) {
		if (typeof window !== `undefined`) {
			let trackingEventOptions = {
				event_action: eventAction,
				event_category: eventCategory,
				event_label: eventLabel,
			}
			//	console.log('EVENT' + JSON.stringify(trackingEventOptions))
			// COMMENTED OUT WHILE I WORK OUT WHY THIS IS FIRING FOR EVERY EVENT ON PAGE LOAD RATHER THAN JUST ON CLICK OF ELEMENT
			if (window.gtag) {
				window.gtag('event', eventAction, trackingEventOptions)
			}
			// NOT REQUIRED IF/WHEN WE GO GTM
			// IF/WHEN WE GO GTM NEED TO SET DATALAYER IN HTMLJS
			// <script>window.dataLayer = window.dataLayer || [];</script>
			// window.dataLayer.push(trackingEventOptions)
		}
	},
	trackConversion(type, metadata) {
		let options = {}
		let conversionId

		const user = getUserInfo()

		switch (type) {
			case 'free_trial':
				options.send_to = 'AW-10902950737/Lkq0CIGb7YwYENGm984o'
				conversionId = 12883097
				break
			case 'contact_us':
				options.send_to = 'AW-10902950737/MyEqCOKY9YwYENGm984o'
				conversionId = 12883089
				break
			case 'request_meeting':
				options.send_to = 'AW-10902950737/X5zTCOf_9YwYENGm984o'
				conversionId = 12883081
				break
		}

		if (typeof window !== `undefined` && window.lintrk) {
			window.lintrk('track', { conversion_id: conversionId })
		}

		const trackOptions = { userEmail: user ? user.email : 'anonymous user email' }

		if (typeof window !== `undefined` && window.analytics) {
			window.analytics.track(type, trackOptions)
		}

		if (typeof window !== `undefined` && window.posthog) {
			window.posthog.capture(type, trackOptions)
		}

		fbq('trackCustom', type)

		// console.log('Firing Gtag Conversion event')
		if (typeof window !== 'undefined' && window.gtag) {
			// console.log('GTAG FOUND')
			// console.log(`Firing conversion event of type: ${type}`)

			// event for google ads
			window.gtag('event', 'conversion', options)
			// event for google analytics
			window.gtag('event', type, options)
			// send conversion to slack channel
			sendConversionToSlack(type, metadata)
		}
	},
	testTrack: function () {
		console.log('TEST')
	},
}

export default tracking
