function getStripeCustomer({ customerId }) {
	if (!customerId) {
		// console.error('customerId is not provided')
		// return null
		return Promise.reject(new Error('customerId is missing'))
	}
	return fetch('/.netlify/functions/getCustomer', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ customerId }),
	})
}

export default getStripeCustomer
