import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './contact.styles.scss'
import CustomButton from '../custom-button/custom-button.component'
import { useCalendlyEventListener, PopupModal } from 'react-calendly'
import tracking from '../../utils/tracking'
import { navigate } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const Contact = ({ title, subtitle, buttonStyle, buttonText, hintStyle, hintText }) => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)

	const handleShow = () => {
		setShow(true)
		// openPopupWidget({ url: 'https://calendly.com/icloudready/request-discovery' })
		tracking.eventTrack('interaction', 'click', 'request_discovery')
	}

	useCalendlyEventListener({
		onEventScheduled: event => {
			// console.log('Event scheduled: ', event)
			tracking.trackConversion('request_meeting', event)
			navigate('/meeting-request-received')
		},
	})

	const { i18n } = useTranslation()

	return (
		<div className="topSection" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
			<h2>
				<Trans>contact_us</Trans>
			</h2>
			<h1 style={i18n.language === 'ar' ? { textAlign: 'right' } : null}>
				<Trans>book_your_free_demo</Trans>
			</h1>
			<div className="contact-Jumbotron">
				<div className="contact-header">
					{/* <h4>{title}</h4> */}
					<h4 style={i18n.language === 'ar' ? { textAlign: 'right' } : null}>
						<Trans>during_this_call_you_can</Trans>
					</h4>
					{/* <h5>{subtitle}</h5> */}
					<h5
						style={
							i18n.language === 'ar'
								? {
										position: 'absolute',
										right: 'unset',
										left: '4%',
										top: '35px',
								  }
								: null
						}
					>
						<Trans>subtitle</Trans>
					</h5>
				</div>
				<p className="contact-hint" style={hintStyle} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
					{/* {hintText} */}
					<Trans>discover_how</Trans>
				</p>
				<CustomButton onClick={handleShow}>
					<Trans>book</Trans>
				</CustomButton>

				<PopupModal
					url="https://calendly.com/icloudready/request-discovery"
					open={show}
					onModalClose={handleClose}
					rootElement={typeof window !== 'undefined' ? window.document.body : null}
				/>
			</div>
			{/* <Modal
				className="booking-modal"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					 <iframe src="https://calendly.com/icloudready/request-discovery" width="100%" height="100%" title="Booking"></iframe> 
				</Modal.Body>
			</Modal> */}
		</div>
	)
}

export default Contact
