import React from 'react'
import './steps.styles.scss'
//import { Link } from "gatsby"
import Link from '../Link'

const Steps = ({ step1, step2, step3 }) => (
	<ul className="steps" id="steps">
		<li className="active-step">
			<Link className="active-step-link" data-track-location="steps" to={step1.to}>
				<span className="step-icon">1</span>
				<span className="step-title">{step1.title}</span>
			</Link>
		</li>
		<li>
			<Link to={step2.to}>
				<span className="step-icon">2</span>
				<span className="step-title">{step2.title}</span>
			</Link>
		</li>
		<li>
			<Link to={step3.to}>
				<span className="step-icon">3</span>
				<span className="step-title">{step3.title}</span>
			</Link>
		</li>
	</ul>
)

export default Steps
