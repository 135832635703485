import CustomCard from "../../../../src/components/custom-card/custom-card.component.jsx";
import automotiveSoftware from "../../../../src/images/automotive-software.svg";
import realEstateSotware from "../../../../src/images/real-estate-software.svg";
import servicesSoftware from "../../../../src/images/services-software.svg";
import * as React from 'react';
export default {
  CustomCard,
  automotiveSoftware,
  realEstateSotware,
  servicesSoftware,
  React
};