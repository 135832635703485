import handleFetchResult from './handleResult';
import createCustomer from './createCustomer';
import createCheckoutSession from './createCheckoutSession';


var requestOptions = {
    method: 'GET',
    redirect: 'follow'
};
var baseurl = process.env.ICLOUD_API

function validateForm(_callback) {
    var FullName = document.forms["myForm"]["inputFullName"].value;
    var Email = document.forms["myForm"]["inputEmail"].value;
    var Password = document.forms["myForm"]["inputPassword"]?document.forms["myForm"]["inputPassword"].value:"";
    var ConfirmPassword = document.forms["myForm"]["inputConfirmPassword"]?document.forms["myForm"]["inputConfirmPassword"].value:"";
    fetch(baseurl + "setup", requestOptions)
        .then(handleFetchResult)
        .then(function(result) {
            console.log(result);
            var publishableKey = result.publishableKey;
            var basicPriceId = result.basicPrice;
            var proPriceId = result.proPrice;
            switch(JSON.parse(localStorage.getItem("state")).productName) {
                case "Customer Engagement" : 
                 basicPriceId = "price_1IYUVgA9cCWaU8bM8bDH78v0";
                 localStorage.setItem('basicPriceId', "price_1IYUVgA9cCWaU8bM8bDH78v0")
                break;
                case "Operations Excellence" :  
                basicPriceId = "price_1IYUWnA9cCWaU8bMJKCWhzwV";
                localStorage.setItem('basicPriceId', "price_1IYUWnA9cCWaU8bMJKCWhzwV")
                break;
                case "Employees Productivity" :  
                basicPriceId = "price_1IYUXrA9cCWaU8bMJ1xhTYA2";
                localStorage.setItem('basicPriceId', "price_1IYUXrA9cCWaU8bMJ1xhTYA2")
                break;
                case "Actionable Insights" : 
                 basicPriceId = "price_1IYUYcA9cCWaU8bMZjwPB7QC";
                 localStorage.setItem('basicPriceId', "price_1IYUYcA9cCWaU8bMZjwPB7QC")
                break;
                case "Market Place" :  
                basicPriceId = "price_1IYUZ9A9cCWaU8bM6bbOYW5x";
                localStorage.setItem('basicPriceId', "price_1IYUZ9A9cCWaU8bM6bbOYW5x")
                break;
                default: console.error("You need to choose product first")
            }
           
           // return createCheckoutSession(result);
            //var stripe = Stripe(publishableKey);
            createCustomer(basicPriceId, FullName , Email , Password).then(function(data) {
                console.log("end: " + data?.sessionId);
                _callback(data);
                //process.env.SESSION_ID=data.sessionId
                // Call Stripe.js method to redirect to the new Checkout page
            })
        })
}

export default validateForm;