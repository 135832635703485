// Handle any errors returned from Checkout
var showErrorMessage = function(message) {
  console.log("error "+message);
};

var handleFetchResult = function(result) {
    if (!result.ok) {
      return result.json().then(function(json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function(err) {
        showErrorMessage(err);
        throw err;
      });
    }
    return result.json();
  };
  
  
  
  var handleResult = function(result) {
  if (result.error) {
  showErrorMessage(result.error.message);
  }
  };

  export default handleFetchResult;
