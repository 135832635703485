import { Button } from 'antd'
import { getAccessToken, getIdToken } from '../util/auth'
import { useCustomer } from '../../hooks/stripe'
import './explore-button.styles.scss'
import { Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

function ExploreButton({ freeTrial = false, ...props }) {
	const { data: customer } = useCustomer()

	if (!customer || !customer.metadata.realmId)
		return freeTrial ? (
			<Link to="/free-trial">
				<Trans>Free Trial</Trans>
			</Link>
		) : null

	return (
		<a
			href={`${process.env.IOPSAPPS_LOGIN_URL}/login/${
				customer.metadata.realmId
			}?accessToken=${getAccessToken()}&idToken=${getIdToken()}`}
			target="_blank"
			className="open-app-link"
			{...props}
		>
			{props.children || 'Launch App'}
		</a>
	)
}

export default ExploreButton
