import React from 'react'
import './products.styles.scss'
import { RightCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'

const Porducts = ({ data }) => {
	const [current, setCurrent] = useState(data[0])
	const pickProduct = id => {
		setCurrent(data.find(item => item.id == id))
	}

	return (
		<div className="productsSection">
			<h2>Bringing together our never before-seen Icloud Ready</h2>
			<div className="productsContainer">
				<ul>
					{data.map(product => (
						<li
							value={product.id}
							key={product.id}
							onClick={e => pickProduct(e.target.value)}
							style={{
								backgroundColor: current.id === product.id ? '#007394' : '#fff',
								color: current.id === product.id ? '#FFFFFF' : '#1F1B1B',
							}}
						>
							{product.title}
						</li>
					))}
				</ul>
				<img src={current.img} alt={current.title} />
				<div className="productInfo">
					{/* <video src=""></video> */}
					<h3>{current.title}</h3>
					<p>{current.subtitle}</p>
				</div>
			</div>
		</div>
	)
}

export default Porducts
