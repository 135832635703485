import useSWR from 'swr'
import fetchPlans from '../../components/util/fetchPlans'
import fetchCustomerSubscriptions from '../../components/util/fetchCustomerSubscriptions'
import handleFetchResponse from '../../components/util/handleFetchResponse'
import { getUserInfo } from '../../components/util/auth'

function getPlansWithSubscriptionData(plans, subscribedPlans) {
	const subscriptions = createSubscribedPlansMap(subscribedPlans)

	return plans.map(plan => {
		return {
			...plan,
			isSubscribed:
				subscriptions.has(plan.id) && !subscriptions.get(plan.id).cancel_at_period_end
					? true
					: false,
			subscriptionData: subscriptions.get(plan.id),
		}
	})
}

// Creates a map for only true subscriptions
function createSubscribedPlansMap(subscribedPlans) {
	const map = new Map()
	for (let sub of subscribedPlans) {
		map.set(sub.plan.id, sub)
		// if (!sub.cancel_at_period_end) map.set(sub.plan.id, sub)
	}
	return map
}

function keepOnlyIopsappsPlans(plans) {
	return plans.filter(
		p =>
			p.nickname.toLowerCase() === 'operation excellence' ||
			p.nickname.toLowerCase() === 'customer engagement'
	)
}

async function fetcher(stripeAccountId) {
	let subscriptions = []
	const user = getUserInfo()
	let stripeId = stripeAccountId
	if (!stripeId) {
		stripeId =
			process.env.NODE_ENV === 'production'
				? user?.['https://icloud-ready.com/stripe_customer_id']
				: user?.['https://icloud-ready.com/stripe_customer_id2']
	}

	if (stripeId) {
		subscriptions = await fetchCustomerSubscriptions({ stripeId })
			.then(handleFetchResponse)
			.then(({ data }) => data)
			.catch(() => [])
	}

	const plans = await fetchPlans()
		.then(handleFetchResponse)
		.then(({ plans }) => keepOnlyIopsappsPlans(plans.data))

	return getPlansWithSubscriptionData(plans, subscriptions)
}

function usePlans({ stripeId } = {}) {
	// console.log('stripeId in plans parent comp' + stripeId)
	const { data, error } = useSWR('plans', () => fetcher(stripeId))
	// console.log('usePlans' + JSON.stringify(data))
	// console.log(data);
	return {
		isLoading: !data && !error,
		error,
		data: data?.filter(plan => plan.metadata.website === 'true'),
	}
}

export default usePlans
