import useSWR from 'swr'
import getStripeCustomer from '../../components/util/getStripeCustomer'
import handleFetchResponse from '../../components/util/handleFetchResponse'
import * as auth from '../../components/util/auth'

function useCustomer() {
	const user = auth.getUserInfo()
	const customerId =
		process.env.NODE_ENV === 'production'
			? user?.['https://icloud-ready.com/stripe_customer_id']
			: user?.['https://icloud-ready.com/stripe_customer_id2']

	const fetcher = () =>
		getStripeCustomer({ customerId })
			.then(handleFetchResponse)
			.then(data => data.customer)

	const { data, error } = useSWR('customer', fetcher)

	return {
		isLoading: !data && !error,
		error,
		data,
	}
}

export default useCustomer
