import { notification } from 'antd'
import { navigate } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import React, { useState } from 'react'
import tracking from '../../utils/tracking'
import contactUsAr from '../../locales/ar/contactUsAr.json'
import contactUsEn from '../../locales/en/contactUsEn.json'

import './mailchimp.styles.scss'

const Mailchimp = ({ dropdownIndustry, industryList, submitText }) => {
	const { i18n } = useTranslation()

	const contactUs = i18n.language === 'ar' ? contactUsAr : contactUsEn

	const [loading, setIsLoading] = useState(false)

	const handleSubmit = async event => {
		event.preventDefault()
		setIsLoading(true)

		const data = Object.fromEntries(new FormData(event.target))
		// console.log('Contact Us Data: ', data)

		const res = await fetch('/.netlify/functions/contact', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})

		setIsLoading(false)

		if (res.ok) {
			tracking.trackConversion('contact_us', data)
			notification.success({ message: contactUs['thanks_message'] })
			navigate('/contact-request-received')
		} else {
			notification.info({
				message: 'Our team received your contact request before and we are working to contact you soon',
			})
		}
	}

	return (
		<>
			<div className="form-parent" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
				<form onSubmit={handleSubmit}>
					{/* <h2>Contact Us</h2> */}
					<h2>{contactUs['contact_us']}</h2>
					{/* <h4>We’re here to help!</h4> */}
					<h4 style={i18n.language === 'ar' ? { textAlign: 'right' } : null}>
						{contactUs['we’re_here_to_help']}
					</h4>
					<div className="form-row">
						<div className="form-group col-6" style={{ paddingRight: '16px' }}>
							<input
								type="text"
								className="form-control"
								name="firstName"
								id="firstName"
								// placeholder="Your First Name"
								placeholder={contactUs['your_first_name']}
								required
							/>
						</div>

						<div className="form-group col-6">
							<input
								type="text"
								className="form-control"
								name="lastName"
								id="lastName"
								// placeholder="Your Last Name"
								placeholder={contactUs['your_last_name']}
								required
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12">
							<input
								type="text"
								className="form-control"
								name="company"
								id="company"
								// placeholder="Your Company Name"
								placeholder={contactUs['your_company_name']}
								required
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12">
							<input
								type="email"
								className="form-control"
								name="email"
								id="email"
								// placeholder="Your Email Address"
								placeholder={contactUs['your_email_address']}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12">
							<input
								type="number"
								className="form-control"
								name="phone"
								id="phone"
								// placeholder="Your Phone Number"
								placeholder={contactUs['your_phone_number']}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12">
							<label
								htmlFor="mce-INDUSTRY"
								style={{
									fontSize: '16px',
									fontWeight: 700,
									margin: '5px 0 10px 5px',
									display: 'inline-block',
								}}
							>
								{/* Interested In */}
								{contactUs['interested_in']}
							</label>
							<select name="title" className="form-control" id="mce-INDUSTRY">
								<option value="" disabled hidden>
									{dropdownIndustry}
								</option>
								{/* <option value="Property Management">{industryList[0]}</option> */}
								<option value="Property Management">
									{contactUs['property_management']}
								</option>
								{/* <option value="Service Management">{industryList[1]}</option> */}
								<option value="Service Management">
									{contactUs['crm_and_customer_engagement']}
								</option>
							</select>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12">
							<textarea
								className="form-control"
								name="notes"
								id="notes"
								rows="5"
								// placeholder="Message"
								placeholder={contactUs['message']}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-12 col-md-6">
							<button
								type="submit"
								className="submit primary-button"
								disabled={loading}
							>
								{/* {loading ? 'Sending...' : submitText} */}
								{loading ? contactUs['sending'] : contactUs['send']}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default Mailchimp
