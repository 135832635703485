import React, { Fragment } from 'react'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { Select } from 'antd'

import './main.styles.scss'
import background from '../../images/main-section-backgroun.png'
import { Link } from 'gatsby'
import styleCircle from '../../images/Path 58154.svg'
import ExploreButton from '../explore-button/explore-button'

const { Option } = Select

const Main = ({ industries }) => {
	const { i18n } = useTranslation()

	const { id, name, info, link, img } = industries[0]

	return (
		<section className="main">
			<div className="textSection">
				<h1 dir={i18n.language === 'ar' ? 'rtl' : 'ltf'}>
					<Trans>Our Property management software is tailored to your needs</Trans>
				</h1>
				{i18n.language === 'ar' ? (
					<h3 dir={i18n.language === 'ar' ? 'rtl' : 'ltf'}>
						حافظ على محفظتك من المنازل والشقق وتحكم في إدارة الممتلكات الخاصة بك. <br />
						<br />
						افضل نظام عقارات متكامل لتسهيل عملية إدارة العقارات من بيع وإيجار وتقارير
						والأوراق المالية ، بالإضافة إلى نظام إدارة قواعد البيانات العقارية
					</h3>
				) : (
					<Fragment>
						<h2>
							Keep your portfolio of homes and apartments and control your Property
							management.
						</h2>
						<h3>
							Invest in our complete real estate software.
							<br /> Experience a powerful set of tools that keep.
							<br />
							you connected and efficient, supporting your
							<br />
							management at all stages of the process.
						</h3>
					</Fragment>
				)}
				{/* <h3>
					<Trans>Invest in our complete real estate software.</Trans>
				</h3>
				<h3 style={{ marginTop: '0' }}>
					<Trans>Experience a powerful set of tools that keep.</Trans>
				</h3>
				<h3 style={{ marginTop: '0' }}>
					<Trans>
						you connected and efficient, supporting your management at all stages of the
						process.
					</Trans>
				</h3> */}
				{/* <h3>
					<Trans> management at all stages of the process.</Trans>
				</h3> */}
				<div>
					{typeof window === 'undefined' ? (
						// <Link to="/free-trial">Free Trial</Link>
						<Link to="/free-trial">
							<Trans>Free Trial</Trans>
						</Link>
					) : (
						// <ExploreButton style={{ textAlign: 'center', fontSize: '18px' }} freeTrial>
						// 	Launch iCloud-Ready Platform
						// </ExploreButton>
						<ExploreButton style={{ textAlign: 'center', fontSize: '18px' }} freeTrial>
							<Trans>Launch iCloud-Ready Platform</Trans>
						</ExploreButton>
					)}

					{/* <Link to="/features">Software Features</Link> */}
					<Link to="/features">
						<Trans>Software Features</Trans>
					</Link>
				</div>
			</div>
			<div className="sectionTwo">
				<div key={id} className="industryCard">
					<img src={img} alt="IMG" />
				</div>
				{i18n.language === 'en' && <img className="circle" src={styleCircle} alt="" />}
			</div>
		</section>
	)
}
export default Main
