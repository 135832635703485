import usePlans from "./usePlans";

function removePlural(str){
    return str.split(" ").map(s => s.replace(/s$/, "")).join(" ");
}

function usePlan({ planId, planName }, planType){
    console.log(planName);
    const { data: plans, ...state } = usePlans();

    if(!plans){
        return state;
    }
    
    // let plan = plans.find(plan => plan.id === planId && plan.metadata.);

    let plan = plans.find(plan => 
            plan.nickname.toLowerCase() === removePlural(planName.toLowerCase()) && plan.metadata.planType === planType);

    if(!plan) return {error: new Error("invalid plan data!")};

    return { data: plan }
}


// for development purposes only

// function usePlan({ planName }){    
//     return {
//         data: {
//             "id": "price_1KRfinA9cCWaU8bMmsY4SYUL",
//             "object": "plan",
//             "active": true,
//             "aggregate_usage": null,
//             "amount": 10000,
//             "amount_decimal": "10000",
//             "billing_scheme": "per_unit",
//             "created": 1644511337,
//             "currency": "usd",
//             "interval": "month",
//             "interval_count": 1,
//             "livemode": true,
//             "metadata": {
//                 "minUsers": "5",
//                 "website": "true",
//                 "planType": "monthly"
//             },
//             "nickname": "Actionable insight",
//             "product": "prod_InbPxoihGjvjVL",
//             "tiers_mode": null,
//             "transform_usage": null,
//             "trial_period_days": 30,
//             "usage_type": "licensed"
//         }
//     }
// }

export default usePlan;