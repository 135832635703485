import createCheckoutSession from './createCheckoutSession'
import { getUserInfo } from './auth'
import fetchCustomerSubscriptions from './fetchCustomerSubscriptions'
import { navigate } from 'gatsby'

var baseurl = process.env.ICLOUD_API

var handleResult2 = function (result) {
	return result.json()
}

var createCustomer = async function (priceId, FullName, Email, Password) {
	// console.log(priceId);
	const profile = getUserInfo()
	const stripeId =
		process.env.NODE_ENV === 'production'
			? profile?.['https://icloud-ready.com/stripe_customer_id']
			: profile?.['https://icloud-ready.com/stripe_customer_id2']

	// console.log('stripeId ' + stripeId)

	/*return fetch(baseurl + "create-customer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                priceId: priceId,
                name: FullName,
                email: Email,
                password: Password,
                env : "dev"
            })
        }).then(handleResult2)
        .then(function(result) {
            console.log(result);
            return createCheckoutSession(result);
        })
        .catch(error => console.log('error', error));*/
	var subscriptions = await (await fetchCustomerSubscriptions({ stripeId })).json()
	//subscriptions=subscriptions.json
	const activeSubscriptions = subscriptions?.data?.filter(
		({ canceled_at, cancel_at }) => !canceled_at || new Date(cancel_at * 1000).getTime() >= new Date()
	)
	const noSubscription = activeSubscriptions?.length
	const hasSubscription = !!activeSubscriptions?.length
	console.log(
		'subscriptions ' +
			JSON.stringify(subscriptions) +
			'activeSubscriptions' +
			JSON.stringify(activeSubscriptions) +
			' number of Subscription ' +
			noSubscription +
			' hasSubscription ' +
			hasSubscription
	)
	if (!hasSubscription) {
		return await createCheckoutSession(stripeId, subscriptions)
	} else {
		return navigate('/account')
	}
	/*const activeSubscriptions = subscriptions?.data?.filter(
        ({ canceled_at, cancel_at }) =>
            !canceled_at ||
            new Date(cancel_at * 1000).getTime() >= new Date()
    );
    const hasSubscription = !!activeSubscriptions?.length;

    if (!hasSubscription) {
       // navigate('/app/profile');
        return createCheckoutSession(subscription);
    }*/
}
export default createCustomer
