import Contacts from "../../../../src/components/contacts/contacts.component.jsx";
import CustomCard from "../../../../src/components/custom-card/custom-card.component.jsx";
import contactLogo from "../../../../src/images/contactus.svg";
import Mailchimp from "../../../../src/components/mailchimp/mailchimp.component.jsx";
import * as React from 'react';
export default {
  Contacts,
  CustomCard,
  contactLogo,
  Mailchimp,
  React
};