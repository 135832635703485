import React from "react";
import "./contacts.styles.scss";
import Contact from "../contact/contact.component";

const Contacts = ({contactData}) => (
    <div className="contacts-parent">
        {contactData.map((contact, index) => (
            <Contact key={index}
                title={contact.title}
                subtitle={contact.subtitle}
                buttonStyle={contact.buttonStyle}
                buttonText={contact.buttonText}
                hintStyle={contact.hintStyle}
                hintText={contact.hintText}
        ></Contact>
        ))}
    </div>
);

export default Contacts;
