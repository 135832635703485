import React from "react";
import { useSWRConfig } from "swr";
import addStripeCard from "../../components/util/addStripeCard";
import useAsync from "../useAsync";
import handleFetchResponse from "../../components/util/handleFetchResponse";
import { message } from "antd";

function useAddCard(){
    const { mutate: swrMutate } = useSWRConfig();
    const { run, ...state } = useAsync();

    React.useEffect(
        () => {
            if(state.isSuccess){
                message.success("Card has been successfully added");
            }
        },
        [state.isSuccess, state.isError]
    )

    const updatePaymentMethods = ({ customerId, token }) =>  async paymentdMethods => {
        const newPaymentMethod = await addStripeCard({ customerId, token })
                                        .then(handleFetchResponse).then(data => data.paymentMethod);
        
        return [newPaymentMethod, ...paymentdMethods];
    }

    const add = ({ customerId, token }) => {
        const promise = swrMutate("paymentMethods", updatePaymentMethods({ customerId, token }), false);
        run(promise);
    }

    return { mutate: add, ...state };
}

export default useAddCard;