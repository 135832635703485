async function handleFetchResponse(res){
    const data = await res.json();

    if(!res.ok){
        const msg = data?.message || res.statusText;
        throw new Error(msg);
    }

    return data;
}

export default handleFetchResponse;