import React, { useState, useEffect } from "react";
import {Link} from "gatsby";
import "./completion.styles.scss";
import CustomButton from "../custom-button/custom-button.component";

const Completion = ({done, congrats, subtitle, buttonList})=> {
    const [target, setTarget] = useState("/")
    const [hideBtn, setHideBtn] = useState(false)
    const signupState = typeof window !== 'undefined'? 
    (JSON.parse(localStorage.getItem("state"))?JSON.parse(localStorage.getItem("state")): {
        productName: "Customer Engagement", 
        planTitle:"Starter Plan",
        planPrice: "1 $"
      }) : {
        productName: "Customer Engagement", 
        planTitle:"Starter Plan",
        planPrice: "1 $"
      };
      useEffect(() => {
        switch(signupState.productName){
            case 'Customer Engagement': 
                setHideBtn(false)
                setTarget("https://idengager-ui-designer.netlify.app/") 
            break;
            case 'Operations Excellence': 
                setHideBtn(false)
                setTarget("https://www.icloud-ready.com")
            break;
            case 'Employees Productivity': 
                setHideBtn(false)
                setTarget("https://www.icloud-ready.com")
            break;
            case 'Actionable Insights':  
                setHideBtn(true)
                setTarget("https://www.icloud-ready.com")
            break;
            case 'Services':  
                setHideBtn(false)
                setTarget("https://www.icloud-ready.com")
            break;
            case 'Market Place':  
                setHideBtn(false)
                setTarget("https://www.icloud-ready.com")
            break;
            default: return;            
        }
    }, [signupState])
 
    return (
    <div className="step-content completion-step" id="step3">
        <div className="step-parent">
            <img className="done-img" src={done} alt="done"/>
            <h2 className="plan-title">
                <span className="blue-line">{congrats}</span>
            </h2>
            <p className="plan-subtitle">{subtitle}</p>
            <p className="email-sent">
                Please check your email to get your application credentials.
            </p>
            {/* <div className="buttons-parent" style={{display: hideBtn?"none":"block"}}>
            {
                buttonList.map((button, index) => (
                    <CustomButton style={button.buttonStyle} key={index}>
                    <a href={target} className="custom-text" style={button.linkStyle}>
                            {button.buttonText}
                    </a>
                    </CustomButton>
                ))
            }
            </div> */}
        </div>
    </div>
)
}

export default Completion;
