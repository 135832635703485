import axios from 'axios'
import { getUserInfo } from './auth'

var baseurl = process.env.ICLOUD_API
var websiteUrl = process.env.WEBSITE_URL

var handleResult2 = function (result) {
	return result.json()
}

var createCheckoutSession = function (stripeId, subscription) {
	const cancelUrl = `${websiteUrl}/signup`
	const successUrl = `${websiteUrl}/completion?session_id={CHECKOUT_SESSION_ID}`
	const url = window ? window.location.origin + window.location.pathname : ''
	const subscriptionSuccessUrl = window && subscription ? `${window.location.origin}/profile` : undefined
	// console.log(" url ",url,"subscriptionSuccessUrl ",subscriptionSuccessUrl);
	return fetch(baseurl + 'create-checkout-session', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			// priceId: result.priceId,
			// Customer: result.Customer,
			priceId: localStorage.getItem('basicPriceId'),
			Customer: stripeId,
			SUCCESS: `${
				subscriptionSuccessUrl ? subscriptionSuccessUrl : successUrl
			}?session_id={CHECKOUT_SESSION_ID}`,
			CANCEL: cancelUrl,
			env: 'dev',
			qty: localStorage.getItem('qty'),
		}),
	})
		.then(handleResult2)
		.then(function (result) {
			console.log('createCheckoutSession ', result)
			return result
		})
		.catch(error => console.log('error', error))
}

/* const callApi = async () => {
  const body = {
    "name": "ahmed_ibrahim2",
    "email": "ahmed_ibrahim@mivors.com",
    "groups": [
        2
    ],
    "disabled_at": null,
    "is_disabled": false,
    "is_invitation_pending": false,
    "is_email_verified": true,
    "auth_type": "password"
  };
  const httpOptions = {
      headers:{ 
        'Authorization': 'Basic QrWcrTFa4nW3exnbTSpxZhy36SALoz8y45rbyZBO',
        'Content-Type': 'application/json',
        'Cookie': 'csrf_token=IjRjOGNmYjIyZGIwMTY5MzMwZjVmNmQxZWMyM2U3MWFmMDhmN2Y3ZjEi.YFtFvA.kBwlwDovtql6nFcQyvcGLaCfQT8; session=.eJwlj0tqBDEMRO_i9QQky7LkuczQ-pGQkEB3shpy9xhCbesVr57tUWder-1ex8eVt_Z4i3ZvEkQ8yxhD-woABShzgaOvOSdT8dCsgQbquJvUpUaalNlyXyELhTSj6zgEmZ3mUtOkOWNvB4srjChLWp59zAOnRPdNLbTebs2vsx7fX-_5uX2Gq5f1HgY4FxEU1wxM75SCR4GW7ODmfq48_090eUE2M6Z0Kt0bCJAYY78bso2d2-8fifdH4g.YFtFvA.nBTSo2DG5SRyFzEEuLzIEOCJBjw',
        'Cache-Control' : 'no-cache',
        'Accept': ''
      }
    };
    axios.defaults.withCredentials = true;
    await axios.post('https://dev.idataworkers.com/default/api/users', body, httpOptions)
  .then((response)=>{
    window.open("https://dev.idataworkers.com/default", "_self")
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  })
} */

export default createCheckoutSession
