import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './signup-form.styles.scss'
import Helmet from 'react-helmet'
import Terms from '../terms/terms.component'
//import { Link } from "gatsby"
import Link from '../Link'
import getStripe from '../util/icloudStripe'
import validateForm from '../util/validateForm'
import createCustomer from '../util/createCustomer'
import ReCAPTCHA from 'react-google-recaptcha'
import { login, logout, getUserInfo, isAuthenticated } from '../util/auth'

const development = process.env
const SignupForm = ({
	productName,
	inputFullName,
	inputEmail,
	inputPassword,
	inputConfirmPassword,
	hintText,
	termsLink,
	submitText,
	siteKey,
	optcheckbox,
}) => {
	const [show, setShow] = useState(false)
	const [showPwd, setShowPwd] = useState(false)
	const [passCaptcha, setPassCaptcha] = useState(false)
	const [cheched, setChecked] = useState(false)
	const recaptchaRef = useRef(null)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const onReCaptchaChange = () => {
		setPassCaptcha(recaptchaRef.current.getValue() ? true : false)
	}
	const [loading, setLoading] = useState(false)
	const redirectToCheckout = async event => {
		event.preventDefault()
		setLoading(true)
		const stripe = await getStripe()
		var SESSION
		validateForm(function (data) {
			console.log("huzzah, I'm done!")
			SESSION = data
			// console.log(SESSION)
			const { error } = stripe.redirectToCheckout({
				sessionId: SESSION?.sessionId,
			})
			if (error) {
				console.warn('Error:', error)
				console.log('Error:', error)
				setLoading(false)
			}
		})
	}

	const handleCheck = e => {
		if (e.target.checked) {
			setChecked(true)
		} else {
			setChecked(false)
		}
	}
	useEffect(() => {
		switch (productName) {
			case 'Customer Engagement':
				setShowPwd(true)
				break
			case 'Operations Excellence':
				setShowPwd(false)
				break
			case 'Employees Productivity':
				setShowPwd(false)
				break
			case 'Actionable Insights':
				setShowPwd(false)
				break
			case 'Services':
				setShowPwd(false)
				break
			case 'Market Place':
				setShowPwd(true)
				break
			default:
				return
		}
	}, [productName])
	if (!isAuthenticated()) {
		login()
		return <p>Redirecting to login...</p>
	}
	return (
		<>
			<Helmet>
				<script src="https://www.google.com/recaptcha/api.js?hl=en" async defer></script>
			</Helmet>
			<div className="signup-form">
				<form name="myForm" onSubmit={redirectToCheckout}>
					<div className="form-row">
						<div className="form-group col-12">
							<input
								type="text"
								className="form-control"
								id="inputFullName"
								placeholder={inputFullName}
								required
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-12">
							<input
								type="email"
								className="form-control"
								id="inputEmail"
								placeholder={inputEmail}
								required
							/>
						</div>
					</div>
					{showPwd ? (
						<div className="form-row">
							<div className="form-group col-12">
								<input
									type="password"
									className="form-control"
									id="inputPassword"
									placeholder={inputPassword}
									pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
									title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
									required
								/>
							</div>
							<div className="form-group col-12">
								<input
									type="password"
									className="form-control"
									id="inputConfirmPassword"
									placeholder={inputConfirmPassword}
									pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
									title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
									required
								/>
							</div>
						</div>
					) : null}
					<div className="form-row">
						<div className="form-group col-12 col-md-6">
							<span className="form-hint">
								{hintText}{' '}
								<button className="terms" onClick={handleShow}>
									{termsLink}
								</button>
							</span>
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={siteKey}
								onChange={onReCaptchaChange}
							/>
						</div>
						<div className="form-group col-12 col-md-6">
							<div className="form-check">
								<label className="form-check-label">
									<input
										type="checkbox"
										className="form-check-input"
										id="optcheckbox"
										name="optcheckbox"
										onClick={handleCheck}
									/>
									Accept terms and conditions
								</label>
							</div>
							<div className="form-check">
								<label className="form-check-label">
									<input
										type="checkbox"
										className="form-check-input"
										name="optcheckbox"
									/>
									Receive Marketing E-Mails
								</label>
							</div>
							<button
								disabled={passCaptcha && cheched ? '' : 'disabled'}
								type="submit"
								className="submit primary-button"
							>
								{submitText}
							</button>
						</div>
					</div>
				</form>
				<div className="modal-container">
					<Modal
						className="terms-modal"
						show={show}
						onHide={handleClose}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>Accept terms and conditions</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Terms />
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</>
	)
}

export default SignupForm
