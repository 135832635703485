import React from 'react'
import { silentAuth, getUserInfo } from './src/components/util/auth'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Storytime } from '@icloudready/storytime'
const stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY)

//export const onServiceWorkerUpdateReady = () => window.location.reload(true);

//export const wrapRootElement = ({ element }) => <ThemeProvider>{element}</ThemeProvider>;
/*
export const onRouteUpdate = ({ location }) => {
  window.analytics && window.analytics.page()
}

export const onInitialClientRender = () => {
  // when leaving website, reset url
  window.onbeforeunload = () => reportUrlChange(RESET_URL)
}*/

class SessionCheck extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
		}
	}

	handleCheckSession = () => {
		this.setState({ loading: false })
	}

	initStoryTime = () => {
		if (window.IDENGAGERCHAT_STORYTIME !== 'disabled') {
			// console.log("this.state.user"+JSON.stringify(this.state.user))
			Storytime.init({
				accountId: process.env.CHAT_ACCOUNT_ID,
				baseUrl: process.env.CHAT_BASE_URL,
				//  customer: { external_id: this.state.?user?.profile?.nickname },
			})
		}
	}

	componentDidMount() {
		silentAuth(this.handleCheckSession)
		this.initStoryTime()
	}

	render() {
		return this.state.loading === false && <React.Fragment>{this.props.children}</React.Fragment>
	}
}

export const wrapRootElement = ({ element, props }) => {
	return (
		<SessionCheck>
			<Elements stripe={stripePromise}>{element}</Elements>
		</SessionCheck>
	)
}
