import React from "react";
import "./Avatar.styles.scss";

function Avatar({ picture, name, size = 50 }){
    const styles = {
        width: `${size}px`,
        height: `${size}px`
    };
    
    return (
        <div className="avatar-wrapper" style={styles}>
            {
                picture ?
                    <img 
                        src={picture}
                        alt="avatar"
                        referrerPolicy="no-referrer"
                    />
                    :
                    <span>{name?.slice(0, 1)}</span>
            }
        </div>
    )
}

export default Avatar;