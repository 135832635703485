import React from 'react'
import { withTranslation, Link as NextLink } from 'gatsby-plugin-react-i18next'
import { Select } from 'antd'

import './header.styles.scss'
//import { Link } from "gatsby"
import Link from '../Link'
import ChatWidget from '@icloudready/chat-widget'
import { Router } from '@reach/router'
import PrivateRoute from '../PrivateRoute'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from '@emotion/styled'
import { spacing, animations } from '../custom-button/styles'
import { Button as BaseButton } from '../custom-button/Buttons'
import Profile from '../Profile'
import button from '../custom-button/custom-button.component'
import SignupForm from '../signup-form/signup-form.component'
import { login, logout, getUserInfo, isAuthenticated } from '../util/auth'
import Avatar from '../avatar/Avatar.component'
import { Modal } from 'antd'
import ExploreButton from '../explore-button/explore-button'
import contactUsAr from '../../locales/ar/contactUsAr.json'
import contactUsEn from '../../locales/en/contactUsEn.json'

class Header extends React.Component {
	state = {
		interface: { active: false },
		profile: {},
		user: { loading: false, error: false, discount: false, profile: {}, customer: {} },
	}

	constructor(props) {
		super(props)
		//  this.state = { interface: {active: false},user: {loading: false, error: false, discount: false, profile: {}, customer: {}}}
		// this.state = { active: false };
		//this.state = { active: false };
		this.handleClick = this.handleClick.bind(this)
	}
	handleClick() {
		this.setState({ interface: { active: !this.state.interface.active } })
	}

	setUserProfile = () => {
		// Load the user info from Auth0.
		const profile = getUserInfo()
		//console.log("profile"+JSON.stringify(profile));
		// If logged in set user.profile
		if (profile && profile.nickname) {
			this.setState(state => ({
				user: {
					...state.user,
					profile: profile,
					loading: true,
				},
			}))

			// Call the IDengager Backend Server
			// and load the customer data
			this.loadCustomer(profile)
		}
	}
	loadCustomer = profile => {
		this.setState(state => ({
			loading: false,
			user: {
				...state.user,
				customer: profile,
				loading: false,
			},
		}))
	}

	componentDidMount() {
		// Mounting Layout on 'callback' page triggers user 'loading' flag
		const location = typeof window !== 'undefined' ? window.location.href : ''

		if (location.pathname === '/callback/') {
			this.setState(state => ({
				user: { ...state.user, loading: true },
			}))
		}

		// Make sure to set user.profile when a visitor reloads the app
		if (location.pathname !== '/callback/') {
			this.setUserProfile()
			//   console.log("this.state.user"+JSON.stringify(this.state))
		}
		// Storytime.

		// if (isBrowser){

		//   this.initStoryTime()

		// }

		// if(getUserInfo() && !getUserInfo().email_verified){
		//   let modal = Modal.warning({
		//     title: "Please verify your email!"
		//   });

		//   setTimeout(() => {
		//     logout();
		//     modal.destroy();
		//   }, 2000)
		// }
	}

	render() {
		// if (!isAuthenticated()) {
		//   login();
		//   return <p>Redirecting to login...</p>;
		// }

		// console.log("isAuthenticated in header: "+isAuthenticated())

		const { t, i18n } = this.props
		// const lang = this.props.i18n.language

		const contactUs = i18n.language === 'ar' ? contactUsAr : contactUsEn

		const buttonStyle = {
			color: '#ffffff',
			// width: '120px',
			border: '1px solid transparent',
			backgroundColor: '#0097C2',
			color: '#fff',
			height: '45px',
			padding: 'auto',
			fontFamily: 'PoppinsM',
			fontSize: '16px',
			fontWeight: 'bold',
			lineHeight: '24px',
			textAlign: 'center',
			textDecoration: 'none',
			borderRadius: '5px',
			outline: 'none',
			marginLeft: '15px',
		}
		const Button = styled(BaseButton)`
			margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
		`
		const navScrolingBehavior = {
			backgroundColor: '#002240',
			top: this.props.scrolling ? '-72px' : '0',
		}

		const navLinks = [
			{
				title: contactUs['home'],
				linkTo: '/',
			},
			{
				title: 'Features',
				linkTo: '/features',
			},
			{
				title: 'pricing',
				linkTo: '/pricing',
			},
			{
				title: contactUs['blogs'],
				linkTo: '/blogs',
			},
			{
				title: contactUs['contact_us'],
				linkTo: '/contactus',
			},
			{
				title: 'FAQs',
				linkTo: '/faqs',
			},
		]
		return (
			<>
				<header className="header" style={navScrolingBehavior}>
					<Link className="logo-parent" data-track-location="header" to={this.props.logoLink}>
						<img
							className="logo-img"
							src={this.props.logo}
							alt="logo"
							width="107px"
							height="54px"
						/>
					</Link>
					{this.props.languageSwitcher}
					<div
						style={{ display: this.props.scrollTop > 0 ? 'none' : 'block' }}
						className="navCircle"
					></div>
					<nav
						className={'nav-links ' + (this.state.interface.active ? 'is-active' : '')}
						style={i18n.language === 'ar' ? { flexDirection: 'row-reverse' } : null}
					>
						{navLinks.map((navLink, index) => (
							<li
								onClick={this.handleClick}
								key={index}
								style={i18n.language === 'ar' ? { marginRight: '7px' } : null}
							>
								{i18n.language === 'ar' &&
								['Features', 'FAQs', 'pricing'].includes(
									navLink.title
								) ? null : (
									<NextLink
										to={navLink.linkTo}
										language={i18n.language}
										className="link-item"
										style={
											i18n.language === 'ar'
												? { fontSize: '16px' }
												: null
										}
									>
										{navLink.title}
									</NextLink>
								)}
								{/* <Link to={navLink.linkTo} className="link-item">
									{lang === 'ar' ? null : navLink.title}
								</Link> */}
							</li>
						))}
						{!isAuthenticated() && i18n.language === 'en' && (
							<button
								style={buttonStyle}
								className="btn btn-link info-btn"
								onClick={e => login('signin')}
							>
								Log In
							</button>
						)}
						{!!isAuthenticated() && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Link
									style={buttonStyle}
									className="btn btn-link info-btn"
									to="/account"
								>
									Profile
								</Link>
							</div>
						)}
					</nav>
					<div className="nav-actions"></div>
					<button
						onClick={this.handleClick}
						type="button"
						aria-label="toggle"
						className={'menu-toggle ' + (this.state.interface.active ? 'is-active' : '')}
					></button>
				</header>
				<ChatWidget
					accountId={process.env.CHAT_ACCOUNT_ID}
					title="Welcome to Icloudready!"
					subtitle="Ask us anything in the chat window below 😊"
					newMessagePlaceholder="Start typing..."
					// customer={{ external_id: customerId }}
					primaryColor="#0097c2"
					greeting="Hi there! How can I help you?"
					baseUrl={process.env.CHAT_BASE_URL}
				/>
			</>
			// accountId: "15a530bd-6ab0-4ccd-8596-465b98767efc",
			// baseUrl: "https://beta.chat.idengager.com",
			// enableStorytime: window.IDENGAGERCHAT_STORYTIME !== "disabled",
		)
	}
}

export default withTranslation()(Header)
