import useSWR from "swr";
import fetchCustomerPaymentMethods from "../../components/util/fetchCustomerPaymentMethods";
import handleFetchResponse from "../../components/util/handleFetchResponse";

function fetcher({ stripeId }){
    return fetchCustomerPaymentMethods({ stripeId })
            .then(handleFetchResponse)
            .then(data => data.data);
}

function usePaymentMethods({ stripeId }){
    const { data, error } = useSWR("paymentMethods", () => fetcher({ stripeId }));

    return {
        isLoading: !data && !error,
        data,
        error
    }
}

export default usePaymentMethods;