/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import {loadStripe} from "@stripe/stripe-js";
// import { Elements } from '@stripe/react-stripe-js';

let stripePromise
let stripeElements
export const isBrowser = typeof window !== 'undefined';

// const getStripe = () => {
  // Only instantiate Stripe if we’re in the browser.
const getStripe = isBrowser
?  () => {
    

  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.STRIPE_PUBLISH_KEY}`)
    // stripeElements = Elements(stripePromise)
  }
  return stripePromise
}: {};

export default getStripe