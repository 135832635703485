import CustomCard from "../../../../src/components/custom-card/custom-card.component.jsx";
import Inroduction from "../../../../src/components/inroduction/inroduction";
import kpi from "../../../../src/images/Real-Estate-kpi.svg";
import idengager from "../../../../src/images/Real-Estate-idengager.svg";
import idataworkers from "../../../../src/images/Real-Estate-idataworkers.svg";
import iopsapps from "../../../../src/images/Real-Estate-iopsapps.svg";
import * as React from 'react';
export default {
  CustomCard,
  Inroduction,
  kpi,
  idengager,
  idataworkers,
  iopsapps,
  React
};