import React, { Fragment, useState } from 'react'

import { Input, Collapse } from 'antd'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import '../../css/faqs.scss'

const { Search } = Input
const { Panel } = Collapse

const FaqsComponent = ({ faqsData }) => {
	const [searchText, setSearchText] = useState('')

	const handleSearch = event => {
		setSearchText(event.target.value)
	}

	const filteredFAQs = faqsData?.filter(faq => faq.question.toLowerCase().includes(searchText.toLowerCase()))

	return (
		<Fragment>
			<Search
				placeholder="Search articles..."
				value={searchText}
				onChange={handleSearch}
				style={{ padding: '1rem' }}
			/>
			<div className="faqs-articles">
				{filteredFAQs.length === 0 ? (
					<p className="no-data">No matching FAQs found.</p>
				) : (
					<Collapse
						expandIconPosition="right"
						ghost
						expandIcon={({ isActive }) =>
							isActive ? (
								<MinusOutlined className="icon" />
							) : (
								<PlusOutlined className="icon" />
							)
						}
					>
						{filteredFAQs.map((faq, index) => (
							<Fragment key={index}>
								<Panel header={faq.question} key={faq.id}>
									<p>{faq.answer}</p>
								</Panel>
								<hr />
							</Fragment>
						))}
					</Collapse>
				)}
			</div>
		</Fragment>
	)
}

export default FaqsComponent
